export default {
    name: "input-comp",
    components: {},
    props: ["id", "label", "type", "placeholder", "validate"],
    data() {
        return {
            dataValue: "",
        };
    },
    computed: {},
    mounted() {},
    methods: {
        monitorEnterKey() {
            this.$emit("value", {
                [this.id]: this.dataValue,
            });
        },
    },
};
